import {classNames} from '@shopify/css-utilities';

interface ShopAppIconProps {
  className?: string;
}

export function ShopAppIcon({className: classNameProp}: ShopAppIconProps) {
  const className = classNames('text-inherit', classNameProp);

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 50 50"
    >
      <path
        fill="currentColor"
        d="M50 12.5C50 5.597 44.403 0 37.5 0h-25C5.597 0 0 5.597 0 12.5v25C0 44.403 5.597 50 12.5 50h25C44.403 50 50 44.403 50 37.5v-25z"
      />
      <path
        fill="#fff"
        d="M14.551 17.49v12.2a.09.09 0 0 0 .092.092h2.249a.091.091 0 0 0 .091-.091v-5.203c0-1.007.676-1.726 1.761-1.726 1.189 0 1.484.969 1.484 1.96v4.969a.09.09 0 0 0 .027.065.09.09 0 0 0 .066.026h2.24a.092.092 0 0 0 .09-.091v-5.266c0-.18-.007-.357-.022-.53a4.681 4.681 0 0 0-.416-1.628c-.52-1.084-1.505-1.785-2.989-1.785a2.989 2.989 0 0 0-2.336 1.195l-.056.066V17.49a.092.092 0 0 0-.091-.092h-2.098a.092.092 0 0 0-.092.092zm-3.441 6.862s-1.088-.256-1.489-.357c-.4-.102-1.101-.328-1.101-.848 0-.544.562-.696 1.138-.696.576 0 1.21.137 1.261.771a.09.09 0 0 0 .09.08l2.108-.008a.091.091 0 0 0 .086-.06.092.092 0 0 0 .005-.036c-.13-2.027-1.915-2.752-3.563-2.752-1.953 0-3.377 1.28-3.377 2.698 0 1.03.294 2 2.597 2.673.402.118.954.27 1.433.4.577.16.884.4.884.784 0 .443-.652.75-1.277.75-.916 0-1.567-.338-1.62-.946a.09.09 0 0 0-.09-.08l-2.104.01a.09.09 0 0 0-.066.028.092.092 0 0 0-.025.066c.096 1.914 1.951 2.945 3.68 2.945 2.57 0 3.74-1.45 3.74-2.796.003-.628-.143-2.075-2.31-2.627zm25.703-2.588V20.54a.088.088 0 0 0-.026-.064.09.09 0 0 0-.065-.027h-2.1a.09.09 0 0 0-.09.09v11.994a.088.088 0 0 0 .026.064.089.089 0 0 0 .065.026h2.249a.09.09 0 0 0 .09-.09v-3.937h.034c.356.542 1.334 1.192 2.608 1.192 2.401 0 4.397-1.98 4.397-4.66 0-2.569-1.984-4.651-4.51-4.651-1.125 0-2.069.62-2.677 1.369v-.082zm2.468 5.747c-1.292 0-2.32-1.072-2.32-2.38 0-1.308 1.027-2.368 2.32-2.368 1.294 0 2.33 1.06 2.33 2.368 0 1.308-1.036 2.38-2.33 2.38zm-11.406-7.554c-2.096 0-3.142.708-3.983 1.28l-.024.016a.205.205 0 0 0-.063.275l.867 1.487a.213.213 0 0 0 .322.056l.065-.054c.432-.36 1.086-.905 2.761-1.04.933-.074 1.74.176 2.33.72.653.601 1.044 1.57 1.044 2.594 0 1.88-1.114 3.064-2.902 3.088-1.474-.008-2.466-.774-2.466-1.906 0-.599.237-1.04.77-1.43a.207.207 0 0 0 .061-.263l-.744-1.402a.215.215 0 0 0-.297-.083c-.836.493-1.822 1.446-1.767 3.182.067 2.21 1.912 3.896 4.31 3.965h.273c2.85-.092 4.907-2.198 4.907-5.048 0-2.637-1.914-5.437-5.463-5.437z"
      />
    </svg>
  );
}
