import type {Variant} from '~/types/cart';
import {isoWindow} from '~/utils/window';

import {getHostname} from './hostname';

interface ConstructLinkProps {
  channel?: string;
  paymentOption?: string;
  sourceToken?: string;
  source?: string;
  variants: string;
}

export function constructLink({
  channel,
  paymentOption,
  source,
  sourceToken,
  variants,
}: ConstructLinkProps): string {
  const hostname = getHostname();

  if (!hostname || !variants.length) return '#';

  const variantsCollection: Variant[] = variants.split(',').map((variant) => {
    const [id, stringQuantity] = variant.split(':');
    const quantity = stringQuantity ? Number(stringQuantity) : 1;
    return {id: Number(id), quantity: isNaN(quantity) ? 1 : quantity};
  });

  const variantsString = variantsCollection
    .map((variant) => `${variant.id}:${variant.quantity}`)
    .join(',');

  const url = new isoWindow.URL(`https://${hostname}/cart/${variantsString}`);
  const params = new URLSearchParams(url.search);

  params.append('payment', paymentOption || 'shop_pay');

  if (source) {
    params.append('source', source);
  }

  if (sourceToken) {
    params.append('source_token', sourceToken);
  }

  if (channel) {
    params.append('channel', channel);
  }

  return `${url.href}?${params}`;
}
