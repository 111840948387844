import {isoWindow} from '~/utils/window';

export const getHostname = () => {
  const storeUrl = isoWindow.location.origin;
  try {
    return new isoWindow.URL(storeUrl).hostname;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`[Shop Pay] Store URL (${storeUrl}) is not valid`, error);
    return null;
  }
};
