import type {FunctionComponent, PropsWithChildren} from 'preact/compat';
import {useMemo, useReducer} from 'preact/compat';

import {AuthorizeStateContext} from './context';
import {reducer} from './reducer';

export const AuthorizeStateProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    autoOpened: false,
    loaded: false,
    modalDismissible: false,
    modalVisible: false,
    sessionDetected: false,
  });

  const value = useMemo(() => {
    const {
      autoOpened,
      loaded,
      modalDismissible,
      modalVisible,
      sessionDetected,
    } = state;

    return {
      autoOpened,
      dispatch,
      loaded,
      modalDismissible,
      modalVisible,
      sessionDetected,
    };
  }, [dispatch, state]);

  return (
    <AuthorizeStateContext.Provider value={value}>
      {children}
    </AuthorizeStateContext.Provider>
  );
};
