interface RetryConfig {
  maxRetries?: number;
  retryDelay?: number;
  signal?: AbortSignal;
}

export async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  {maxRetries = 3, retryDelay = 1000, signal}: RetryConfig = {},
): Promise<T | undefined> {
  const executeWithRetry = async (retryCount = 0): Promise<T | undefined> => {
    if (signal?.aborted) return undefined;

    try {
      return await operation();
    } catch (error) {
      if (signal?.aborted) return undefined;

      if (retryCount < maxRetries - 1) {
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
        if (signal?.aborted) return undefined;
        return executeWithRetry(retryCount + 1);
      }
      throw error;
    }
  };

  return executeWithRetry();
}
